.links {
  display: flex;
  flex-wrap: wrap;
  max-width: 450px;
  min-height: 100%;
  justify-content: flex-start; 
}

.links a {
  font-size: 1rem;
  text-decoration: none;
  margin-right: 3px;
  color: #61686e;
}

.links a:hover {
  text-decoration: underline;
}

.icon {
  margin-right: 4px;
  font-size: 1.3rem;
  cursor: pointer;
  margin-left: 2px;
}

.social-link {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-width: 1px;
  border-radius: 0.4em;
  padding: 1px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.social-link:hover {
  background-color: #ecb46514;
}

