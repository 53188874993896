@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
:root {
  --dark: #041C32;
  --light: #E5E8EA;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s, color 0.3s;
  overflow-y: hidden;
}

.App {
  height: 100svh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.contents {
  padding: 20px;
  display: flex;
  flex-direction: column;
  animation: animate__zoomIn; 
  animation-duration: 0.5s; 
}
body.light-theme {
  background-color: var(--light);
  color: var(--dark);
}

body.dark-theme {
  background-color: var(--dark);
  color: var(--light);
}




