/*#041C32, #04293A,#064663, #ECB365, #818d98*/
.profile {
    text-align: left;
    width: 100%;
    max-width: 450px;
    justify-content: space-between;
    min-height: 100%;
}

.header-profile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.sign {
  display: flex;
  flex-direction: column;
  text-align: left;
  /*margin-right: 3rem; add to lock responsive*/
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 20%;
  cursor: pointer;
  background-color: #68768460;
  margin-bottom: 1em;
}

h2 {
  font-size: 1.9em;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  /*min-width: 270px; add to lock responsive*/
  font-weight: 750;
}

.desc {
  font-size: 1.1rem;
  margin-top: 1px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-flow:wrap;
}

.desc-job {
  display: flex;
}

.job-name {
  margin-right: 0.32em;

  height: auto;
  display: flex;
  align-items: center;
}
.company-name {
  height: auto;
  display: flex;
  align-items: center;

  margin-left: -0.08em;
}

.icon-class {
  min-width: 18px;
  margin-right: 0.05em;
  margin-bottom: 0.1em;

  height: auto;
  display: flex;
  align-items: center;
}

.paragraph {
  font-size: 0.9rem;
  margin-top: 0px;
  margin-top: 1px;
  display: inline-flex;
}

.icon-location {
  font-size: 15px;
  color: #ecb46581;
}
.icon-job {
  font-size: 15px;
  opacity: 0.7;
  padding-bottom: 1px;
}
.icon-at {
  font-size: 15px;
  opacity: 0.7;
  margin-right: 0.32em;
}

.location {
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.desc-company {
  display: flex;
  flex-wrap: wrap;
  font-style: normal;
  /*font-weight: 500;*/
  opacity: 0.8;
}


@media (max-width: 768px) {
  .profile {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .header-profile {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .profile-image {
    margin-left: 0;
    order: -1; 
  }
}


