
.theme-switch {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  transition: color 0.3s;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  padding: 20px;
  margin-top: 30px;
  color: var(--dark);
}

body.dark-theme .theme-switch {
  color: var(--light);
}

@media (max-height: 480px) {
  .theme-switch {
    margin-top: 0px !important;
  }
}
  
